import styled from 'styled-components';
import Thebranding from '../components/Home/Thebranding';
import brandingdata from '../Json/brandingdata.json';
import React from 'react'

import Layout from "../components/layout"
import Seo from "../components/seo"



const SectionBrandinGroup = styled.div`
  margin: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1em;
  justify-items: center;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
`;

export default function BrandingPage() {
  return (
    <Layout>
    <Seo title="Branding" />
    <div className="Hero">
      <div className="HeroGroup">
      <h2>BrandingCompany Profile and Startup</h2>
        <SectionBrandinGroup>
          {brandingdata.thebrandings.map((thebranding) => (
            <Thebranding
              title={thebranding.title}
              image={thebranding.image}
              text={thebranding.text}
              url={thebranding.url}
            />
          ))}
        </SectionBrandinGroup>
      </div>
    </div>
    </Layout>
  );
}
