import styled from 'styled-components';
import React from 'react'

const StackGroup = styled.div`
  width: 100%;
  height: 400px;
  overflow: hidden;
  border-radius: 18px;

  &:hover {
    transform: scale(1.05, 1.05);
  }

  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const StackMain = styled.div`
  width: 100%;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center center;
  background-repeat: repeat-x;
  border-radius: 18px;
  height: 300px;

  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);

  @media (max-width: 600px) {
    height: 300px;
  }
`;

const StackTitle = styled.div`
  color: black;
  font-weight: 600;
  font-size: 18px;
  padding: 20px 20px 5px 20px;
`;
const StackText = styled.div`
  color: black;
  font-weight: 300;
  font-size: 16px;
  align-self: end;
  margin: 0 0 20px 20px;
`;

const Bonk = styled.div`
  text-align: right;
  width: 100%;
  cursor: pointer;
  position: absolute;
  bottom: 0;

  a {
    font-size: 12px;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    background: black;
    border-radius: 18px;
  }

  a {
    cursor: pointer;
    color: white;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }

  a:after {
    content: '»';
    position: absolute;
    opacity: 0;
    right: 0px;
    transition: 0.5s;
  }

  a:hover {
    padding-right: 24px;
    color: black;
    background: #ffe701;
  }

  a:hover:after {
    opacity: 1;
    right: 10px;
  }
`;

const StackAll = styled.div`
  width: 100%;
  min-height: auto;
  position: relative;
  border-radius: 18px;
`;

const Thebranding = (props) => (
  <StackAll>
    <StackGroup>
      <a href={props.url}>
        <StackMain image={props.image} />
        <StackTitle>{props.title}</StackTitle>
        <StackText>{props.text}</StackText>
      </a>
    </StackGroup>
  </StackAll>
);

export default Thebranding;
